// ** Redux Imports
import { handleLogin } from '../authentication'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Firebase
import { updateProfile } from 'firebase/auth'
import { ref as dbRef, update } from "firebase/database"
import { ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage'
import { auth, db, storage } from '@configs/firebase'


const avatarsTableName = 'avatars',
  usersTableName = 'users',
  avatarsAccessPath = `${process.env.NODE_ENV}/${avatarsTableName}`,
  usersAccessPath = `${process.env.NODE_ENV}/${usersTableName}`

/**
 * Used by getUsersListener in src/db/realtime-db/users
 * Spread users accross the app, each time getUsersListener provides updates on users
 */
 export const getUsers = createAsyncThunk('users', async users => {
  return users
})

/**
 * Update Avatar on Authentication side and Realtime Db side
 */
export const updateAvatar = createAsyncThunk('users/update', async ({ file }, { dispatch }) => {
  const avatarRef = storageRef(storage, `${avatarsAccessPath}/${auth.currentUser.uid}`)
  try {
    // Upload
    const snapshot = await uploadString(avatarRef, file, 'data_url')
    // Get url
    const photoURL = await getDownloadURL(snapshot.ref)
    // Update Authentication
    await updateProfile(auth.currentUser, { photoURL })
    // Update Realtime DB
    const updates = {}
    updates[`${usersAccessPath}/${auth.currentUser.uid}/avatar`] = photoURL
    await update(dbRef(db), updates)
    // Reload user
    dispatch(handleLogin(auth.currentUser))
  } catch (error) {
    throw new Error(error)
  }
})

/**
 * Update name on Authentication side and Realtime Db side
 */
export const updateName = createAsyncThunk('users/update', async ({ displayName }, { dispatch }) => {
  try {
    // Update on Authentication
    await updateProfile(auth.currentUser, { displayName })
    // Update on Realtime db
    const updates = {}
    updates[`${usersAccessPath}/${auth.currentUser.uid}/name`] = displayName
    await update(dbRef(db), updates)
    // force a dispatch here, though it's been done in App.js with onAuthChange, because it's only been done once
    dispatch(handleLogin(auth.currentUser))
  } catch (error) {
    throw error
  }
})


export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    all: []
  },
  extraReducers: builder => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.all = action.payload
      })
  }
})

export default usersSlice.reducer