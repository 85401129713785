import { badgeGroups } from '@constants/db'

//** Redux Imports */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

/**
 * Used in src/db/realtime-db/...
 * Spread data accross the app, each time getXXXListener provides updates from Firebase
 */
export const getBadges = createAsyncThunk('badges', async badges => {
  return badges
})

export const badgesSlice = createSlice({
  name: 'badges',
  initialState: {
    data: [],
    byTag: []
  },
  extraReducers: builder => {
    builder
      .addCase(getBadges.fulfilled, (state, action) => {
        state.data = action.payload
        state.byTag = badgeGroups.map(badgeGroup => {
          return {
            label: badgeGroup.label.fr,
            options: action.payload.filter(badge => badge.groupId === badgeGroup.id).map(badge => ({ label: badge.label.fr, id: badge._id }))
          }
        })
      })
  }
})

export default badgesSlice.reducer