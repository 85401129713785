// ** Third Party Components
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Avatar from '@components/avatar'
import { X } from 'react-feather'
import { imageKeyWords } from '@constants/db'
import Select from 'react-select'

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

/**
 * UI Modal / Toast
 */
const MySwal = withReactContent(Swal)
export const showInfo = ({ title, text }) => {
  MySwal.fire({
    icon: 'info',
    title,
    text,
    customClass: {
      confirmButton: 'btn btn-info outline'
    }
  })
}
export const showError = ({ title, text }) => {
  MySwal.fire({
    icon: 'error',
    title,
    text,
    customClass: {
      confirmButton: 'btn btn-danger outline'
    }
  })
}

/**
 * Returns the array of images that match key words
 */
export const filterImagesByKeywords = ({ keyWords, images }) => {
  return images.filter((image) => {
    for (const keyWord of image.keyWords) {
      if (keyWords.includes(keyWord)) {
        return true
      }
    }
    return false
  })
}

/**
 * Render Select image key words
 */
export const renderSelectImageKeyWords = ({ setKeyWords }) => {
  return <Select
    options={imageKeyWords.map(keyWord => ({ value: keyWord, label: keyWord }))}
    onChange={options => {
      if (options.length === 0) {
        setKeyWords([])
      } else {
        setKeyWords(options.map(o => o.value))
      }
    }}
    isMulti
    theme={selectThemeColors}
    placeholder='Mots clés...'
    className='react-select mb-1'
    classNamePrefix='select'
    id={`keyWords`}
    />
}

/**
 * UI Warning Toast
 */
export const WarningToast = ({ text }) => (
  <>
    <div className='toastify-header'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='danger' icon={<X size={12} />} />
        <h6 className='toast-title'>Attention !</h6>
      </div>
    </div>
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {text}
      </span>
    </div>
  </>
)

/**
 * UI Render file size
 */
export const renderFileSize = size => {
  if (Math.round(size / 100) / 10 > 1000) {
    return `${(Math.round(size / 100) / 10000).toFixed(1)} Mo`
  } else {
    return `${(Math.round(size / 100) / 10).toFixed(1)} Ko`
  }
}

/**
 * Regex
 */
export const stringFitsNameRegexWithNumbers = text => {
  return /^[0-9a-z_]+$/.test(text)
}

/**
 * Check names without symbols, numbers, spaces or accents
 */
export const namesAreValid = images => {
  for (const image of images) {
    if (/^[a-z_]+\.svg$/.test(image.name) === false) {
      return false
    }
  }
  return true
}

/**
 * Check names without symbols, spaces or accents
 */
export const namesWithNumbersAreValid = images => {
  for (const image of images) {
    if (/^[a-z0-9_]+\.svg$/.test(image.name) === false) {
      console.log(image.name)
      return false
    }
  }
  return true
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Checks if array is an Array and not empty
export const isArrayEmpty = array => array instanceof Array && array.length === 0

/**
 * Returns a deep copy of nested elements
 * @param {array or object} src the element to deep copy
 * @returns copy
 */
export const deepCopy = src => {
  const circularKeys = ['root', 'parent']
  const target = Array.isArray(src) ? [] : {}
  for (const key in src) {
    if (circularKeys.includes(key) === false) {
      const v = src[key]
      if (v) {
        if (typeof v === "object") {
          target[key] = deepCopy(v)
        } else {
          target[key] = v
        }
      } else {
        target[key] = v
      }
    }
  }
  return target
}

// ** Returns K format from a number
export const kFormatter = num => {
  // A negative number will be an impact, so it must be Math.ceil()
  if (num <= -1000000) {
    return `${Math.ceil(num / 1000000)}M`
  }
  if (num <= -10000) {
    return `${Math.ceil(num / 1000)}k`
  }
  if (num <= -1000) {
    return `${Math.ceil(num / 100) / 10}k`
  }
  if (num <= 0) {
    return num
  }
  if (num < 1000) {
    return `+${num}`
  }
  // A positive number could be either an impact or a statement, it must be Math.floor()
  if (num < 10000) {
    return `+${Math.floor(num / 100) / 10}k`
  }
  if (num < 1000000) {
    return `+${Math.floor(num / 1000)}k`
  } else {
    return `+${Math.floor(num / 1000000)}M`
  }
}

export const priceFormatter = input => {
  const str = input.toString()
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const cleanText = text => {
  // trim and remove double space
  let cleanText = text.replace(/\s+/g, ' ').trim()
  // add space before $
  const regex = /(\d+)\$/g
  cleanText = cleanText.replace(regex, '$1 $')
  if (cleanText.length === 0) {
    return cleanText
  }
  return cleanText.charAt(0).toUpperCase() + cleanText.slice(1)
}

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('fr-Fr', formatting).format(new Date(value))
}

/**
 ** Format and return date in Humanize format, in alphabetical order
 * @returns string
 */
export const getCurrentDateTimeForFirestore = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const day = now.getDate().toString().padStart(2, '0')
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')

  const formattedDateTime = `${year}-${month}-${day}-${hours}:${minutes}`
  return formattedDateTime
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('fr-Fr', formatting).format(new Date(value))
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

/**
 * Depth first search with a function to apply
 * @param {object} param0 props
 */
export const depthFirstSearch = ({ funcToApply, node, root }) => {
  
  // apply any function here
  funcToApply({ node, root })

  if (node.children instanceof Array) {
    node.children.forEach(child => {
      if (!isObjEmpty(child)) {
        depthFirstSearch({ funcToApply, node: child, root })
      }
    })
  }
}

/**
 * A depth first search function working with arrays of ids
 * @param {function} funcToApply any function to apply
 * @param {array} allNodes all nodes
 * @param {integer} nodeId the node id
 */
export const depthFirstSearchWithIds = (funcToApply, allNodes, nodeId) => {

  const node = allNodes.find(n => n._id === nodeId)
  funcToApply(node)

  if (node.children instanceof Array) {
    node.children.forEach(childId => {
      depthFirstSearchWithIds(funcToApply, allNodes, childId)
    })
  }
}

/**
 * Returns an array of strings of lengths <= maxLength
 * @param {string} text the text to split
 * @param {integer} maxLength the number of chars max per line
 * @returns Array
 */
export const getSplitText = (text, maxLength) => {

  // Init
  const split = []
  let index = 0, foundSpace = false

  // While index hasn't gone all the way
  while (index < text.length) {
    // If text length fits a line, add it all
    if (text.length - index <= maxLength) {
      split.push(text.substr(index))
      index += maxLength
    } else {
      // search for the nearest space character and split there
      foundSpace = false
      for (let j = maxLength + index; j > index; j--) {
        if (text[j] === " ") {
          split.push(text.substring(index, j))
          index = j + 1
          foundSpace = true
        }
      }
      if (!foundSpace) {
        console.error(`Truc pas normal : pas d'espace`)
      }
    }
  }
  return split
}

/**
 * Nice gradient from green to red
 */
const percentColors = [
  { pct: 0.0, color: { r: 234, g: 84, b: 85 } }, // red
  { pct: 0.5, color: { r: 255, g: 159, b: 67 } }, // yellow
  { pct: 0.75, color: { r: 255, g: 226, b: 67 } }, // orange
  { pct: 1.0, color: { r: 40, g: 199, b: 111 } } // green
]

/**
 * Returns the color matching a percentage, from red (0) to green (1)
 * @param {float} pct number between 0 and 1
 * @returns string
 */
export const getColorForPercentage = pct => {
  let i = 1
  for (; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break
    }
  }
  const lower = percentColors[i - 1]
  const upper = percentColors[i]
  const range = upper.pct - lower.pct
  const rangePct = (pct - lower.pct) / range
  const pctLower = 1 - rangePct
  const pctUpper = rangePct
  const color = {
      r: Math.floor((lower.color.r * pctLower) + (upper.color.r * pctUpper)),
      g: Math.floor((lower.color.g * pctLower) + (upper.color.g * pctUpper)),
      b: Math.floor((lower.color.b * pctLower) + (upper.color.b * pctUpper))
  }
  return `rgb(${[color.r, color.g, color.b].join(',')})`
}

/**
 * Returns whether a node has a choice
 * @param {object} node d3 node
 * @returns boolean
 */
export const nodeHasChoice = node => {
  return typeof node.choice === 'object' && node.choice !== null 
}

/**
 * Returns whether a node has an event sequence
 * @param {object} node d3 node
 * @returns boolean
 */
export const nodeHasEventSeq = node => {
  return Array.isArray(node.eventSeq) && node.eventSeq.length > 0
}

/**
 * Returns a sorted array like [0, 1] or [1] or [1, 5]: the players contained in the string
 * @param {string} string a string
 * @returns array
 */
export const stringHasPlayers = string => {
  const playerNumbers = []
  for (let i = 0; i < 9; i++) {
    if (string.includes(`$p${i}`)) {
      playerNumbers.push(i)
    }
  }
  return playerNumbers
}

/**
 * Returns the string, for forms that use a select field with an ID that should be a string
 * @param {any} option object { option: *string*, id: *string* } or string
 * @returns 
 */
export const optionFieldToString = option => {
  if (option === null) {
    return null
  }
  if (typeof option === 'object') {  // object = { id: string, label: string }
    if (typeof option.id === 'string') {
      return option.id.length ? option.id : null
    } else if (typeof option._id === 'string') {
      return option._id.length ? option._id : null
    }
  }
  if (typeof option === 'string') { // string = badgeId: string
    return option.length ? option : null
  }
  return null
}

/**
 * Returns the integer, for forms that use a select field
 * @param {any} option object { option: *string*, id: *integer* } or integer
 * @returns 
 */
 export const optionFieldToInteger = option => {
  if (option === null) {
    return null
  }
  if (typeof option === 'object') {  // object = { id: integer, label: string }
    return option.id
  }
  if (typeof option === 'integer') { // integer = badgeId: integer
    return option
  }
  return null
}

/**
 * Returns an array of integers, for forms that use a select field
 * @param {any} options array [{ array: *string*, id: *integer* }] or integer
 * @returns 
 */
 export const multiOptionFieldToInteger = options => {
  if (options === null) {
    return null
  }
  if (isArrayEmpty(options)) {
    return null
  }
  if (options instanceof Array) {  // object = { id: integer, label: string }
    return options.map(a => a.id)
  }
  if (typeof options === 'integer') { // integer = badgeId: integer
    return options
  }
  return null
}

/**
 * Returns an integer, like "4 500 000" => 4500000
 * @param {any} value 
 * @returns integer
 */
export const anyToInteger = value => {
  if (typeof value === 'string') {
    return parseInt(value.replace(/\s+/g, ''))
  }
  return parseInt(value)
}

/**
 * Returns situations found, their tags and goals
 * @param {integer} badgeId badge id
 * @param {array} situations situations
 * @param {array} goals goals
 * @returns object
 */
export const whereThisBadgeIs = (badgeId, situations, goals, activities) => {
  if (goals.length === 0) {
    return {
      notFound: true,
      situationsFound: [],
      goalsFound: '',
      activitiesFound: ''
    }
  }
  const situationsFound = []
  const goalsFound = {}
  const activitiesFound = {}
  // Sort the situations by ids in order to remove same occurences easily afterwards
  situations.slice().sort((s1, s2) => s1._id - s2._id)

  // Function that will be applied to each node, depth searching
  const addToArray = ({ node, root }) => {
    if (node.eventSeq instanceof Array) {
      node.eventSeq.forEach(event => {
        if (event.badgeId === badgeId) {
          // Only add choice if it's not in the array yet.
          // Because the array is sorted, only check the last element added
          if (!situationsFound.length || situationsFound[situationsFound.length - 1].id !== root._id) {
            situationsFound.push({ 
              id: root._id,
              goalId: root.goalId,
              activityId: root.activityId
            })
          }
        }
      })
    }
  }
  situations.forEach(situation => {
    if (situation.children instanceof Array) {
      situation.children.forEach(child => {
        depthFirstSearch({ 
          funcToApply: addToArray,
          node: child,
          root: situation
        })
      })
    }
  })
  situationsFound.forEach(situation => {
    // If situation is linked to a goal
    if (typeof situation.goalId === 'string') {
      const goal = goals.find(g => g._id === situation.goalId)
      if (goalsFound[goal._id] === undefined) {
        goalsFound[goal._id] = { 
          nb: 1, 
          label: goal.label.fr
        }
      } else {
        goalsFound[goal._id].nb++
      }
    } else { // Otherwise, if situation is linked to an activity
      const activity = activities.find(a => a._id === situation.activityId)
      if (activitiesFound[activity._id] === undefined) {
        activitiesFound[activity._id] = { 
          nb: 1, 
          label: activity.label.fr
        }
      } else {
        activitiesFound[activity._id].nb++
      }
    }
  })
  return {
    notFound: isObjEmpty(goalsFound) && isObjEmpty(activitiesFound) && situationsFound.length === 0,
    situationsFound,
    goalsFound: isObjEmpty(goalsFound) ? '' : Object.values(goalsFound).map(({ nb, label }) => `${label} (${nb})`),
    activitiesFound: isObjEmpty(activitiesFound) ? '' : Object.values(activitiesFound).map(({ nb, label }) => `${label} (${nb})`)
  }
}

/**
 * Used with the function after
 */
const addNbOccurencesOfActivityImageREC = ({ result, imageId, node, situationId }) => {
  if (node.choice) {
    if (node.choice.imageId === imageId) {
      result.nbOccurrencesFound++
      if (result.situationIdsFound.indexOf(situationId) === -1) {
        result.situationIdsFound.push(situationId)
      }
    }
  }
  if (node.children && node.children.length === 2) {
    addNbOccurencesOfActivityImageREC({ result, imageId, node: node.children[0], situationId })
    addNbOccurencesOfActivityImageREC({ result, imageId, node: node.children[1], situationId })
  }
}

/**
 * Get nb of occurrences of an image in all situations
 */
export const getNbOccurencesOfActivityImage = ({ imageId, situations }) => {
  const result = {
    situationIdsFound: [],
    nbOccurrencesFound: 0
  }
  for (const situation of situations) {
    addNbOccurencesOfActivityImageREC({ result, imageId, node: situation, situationId: situation._id })
  }
  return result
}

/**
 * Used with the function after
 */
const addNbOccurencesOfCharacterImageREC = ({ result, imageId, node, situationId }) => {
  if (node.choice) {
    if (node.choice.speaker && `${node.choice.speaker}.svg` === imageId) {
      result.nbOccurrencesFound++
      if (result.situationIdsFound.indexOf(situationId) === -1) {
        result.situationIdsFound.push(situationId)
      }
    }
  }
  if (node.eventSeq && Array.isArray(node.eventSeq)) {
    for (const event of node.eventSeq) {
      if (typeof event.speaker === 'string') {
        if (`${event.speaker}.svg` === imageId) {
          result.nbOccurrencesFound++
          if (result.situationIdsFound.indexOf(situationId) === -1) {
            result.situationIdsFound.push(situationId)
          }
        }
      }
    }
  }
  if (node.children && node.children.length === 2) {
    addNbOccurencesOfCharacterImageREC({ result, imageId, node: node.children[0], situationId })
    addNbOccurencesOfCharacterImageREC({ result, imageId, node: node.children[1], situationId })
  }
}

/**
 * Get nb of occurrences of a character in all situations
 */
export const getNbOccurencesOfCharacterImage = ({ imageId, situations }) => {
  const result = {
    situationIdsFound: [],
    nbOccurrencesFound: 0
  }
  for (const situation of situations) {
    addNbOccurencesOfCharacterImageREC({ result, imageId, node: situation, situationId: situation._id })
  }
  return result
}

/**
 * Add character names in an array - recursive
 */
export const addCharacterNameREC = ({ characterNames, node }) => {
  if (node.choice) {
    if (typeof node.choice.speaker === 'string' && !node.choice.speaker.startsWith('$f')) {
      const fileName = `${node.choice.speaker}.svg`
      if (characterNames.indexOf(fileName) === -1) {
        characterNames.push(fileName)
      }
    }
  }
  if (node.eventSeq && Array.isArray(node.eventSeq)) {
    for (const event of node.eventSeq) {
      if (typeof event.speaker === 'string' && !event.speaker.startsWith('$f')) {
        const fileName = `${event.speaker}.svg`
        if (characterNames.indexOf(fileName) === -1) {
          characterNames.push(fileName)
        }
      }
    }
  }
  if (node.children && node.children.length === 2) {
    addCharacterNameREC({ characterNames, node: node.children[0] })
    addCharacterNameREC({ characterNames, node: node.children[1] })
  }
}