// For more examples, see src/views/apps/user/stor/index.js in Vuexy full template

//** Redux Imports */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const getFriends = createAsyncThunk('friends', async friends => {
  return friends
})

export const friendsSlice = createSlice({
  name: 'friends',
  initialState: {
    data: []
  },
  extraReducers: builder => {
    builder
      .addCase(getFriends.fulfilled, (state, action) => {
        state.data = action.payload.sort((a, b) => a.name.fr.localeCompare(b.name.fr, 'fr', { sensitivity: 'base' }))
      })
  }
})

export default friendsSlice.reducer