//** Redux Imports */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const getSituations = createAsyncThunk('situations', async situations => {
  return situations
})

export const situationsSlice = createSlice({
  name: 'situations',
  initialState: {
    data: []
  },
  extraReducers: builder => {
    builder
      .addCase(getSituations.fulfilled, (state, action) => {
        state.data = action.payload.sort((s1, s2) => { return s1.label < s2.label ? -1 : 1 })
      })
  }
})

export default situationsSlice.reducer