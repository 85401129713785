//** Redux Imports */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

/**
 * Used in src/db/realtime-db/...
 * Spread data accross the app, each time getXXXListener provides updates from Firebase
 */
export const getActivities = createAsyncThunk('activities', async activities => {
  return activities
})

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState: {
    data: []
  },
  extraReducers: builder => {
    builder
      .addCase(getActivities.fulfilled, (state, action) => {
        state.data = action.payload.sort((a, b) => a.label.fr.localeCompare(b.label.fr, 'fr', { sensitivity: 'base' }))
      })
  }
})

export default activitiesSlice.reducer