// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { auth } from '@configs/firebase'

// ** Utils Imports
import { isObjEmpty } from '@utils'

const initUser = () => {
  if (auth.currentUser === null || isObjEmpty(auth.currentUser)) {
    return null
  }
  return auth.currentUser
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: initUser(),
    userName: auth.currentUser?.displayName, // Never used, but updating that prop in handleLogin force a re-render in UserDropdown.js
    isAdmin: false
  },
  reducers: {
    handleLogin: (state, action) => {
      state.currentUser = action.payload
      state.userName = state.currentUser.displayName
      state.isAdmin = action.payload.email === 'stef@arcs.fun'
    },
    handleLogout: state => {
      state.currentUser = null
      state.userName = ''
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
