//** Redux Imports */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const getGoals = createAsyncThunk('goals/get', async goals => {
  return goals
})

export const goalSlice = createSlice({
  name: 'goals',
  initialState: {
    data: [],
    dataAlphabeticallySorted: []
  },
  reducers: {
    resetGoals: state => {
      state.data = []
      state.dataAlphabeticallySorted = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getGoals.fulfilled, (state, action) => {
        state.data = action.payload
        state.dataAlphabeticallySorted = action.payload.sort((g1, g2) => { return g1.label.fr < g2.label.fr ? -1 : 1 })
      })
  }
})

export const {
  resetGoals
} = goalSlice.actions

export default goalSlice.reducer