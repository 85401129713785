// Hardcoded
export const badgeGroups = [
  { 
    id: "badgeGroup1",
    label: {
      fr: 'Aventurier'
    },
    imageAsset: 'badgeGroup1.svg'
  }, 
  { 
    id: "badgeGroup2",
    label: {
      fr: 'Héros'
    },
    imageAsset: 'badgeGroup2.svg'
  },
  { 
    id: "badgeGroup3",
    label: {
      fr: 'Stupide'
    },
    imageAsset: 'badgeGroup3.svg'
  }
]

export const shopCoins = [
  // poor
  { 
    id: "coins_poor_1",
    wealthStatusId: 'poor',
    gainMoney: 100,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F100_coins.svg?alt=media&token=c5cb35e7-c1ce-4861-911b-09b443f5af87",
    label: {
      fr: "100 coins"
    },
    price: 20
  },
  {
    id: "coins_poor_2",
    wealthStatusId: 'poor',
    gainMoney: 400,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F500_coins.svg?alt=media&token=3047ce2b-8b2a-4df6-b43f-188b187e509c",
    label: {
      fr: "400 coins"
    },
    price: 40
  },
  {
    id: "coins_poor_3",
    wealthStatusId: 'poor',
    gainMoney: 1000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F1000_coins.svg?alt=media&token=b3fe86a5-6c60-4d1b-b4a7-cff2b396b848",
    label: {
      fr: "1.000 coins"
    },
    price: 80
  },
  // normal
  {
    id: "coins_normal_1",
    wealthStatusId: 'normal',
    gainMoney: 1000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F100_coins.svg?alt=media&token=c5cb35e7-c1ce-4861-911b-09b443f5af87",
    label: {
      fr: "1.000 coins"
    },
    price: 20
  },
  {
    id: "coins_normal_2",
    wealthStatusId: 'normal',
    gainMoney: 4000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F500_coins.svg?alt=media&token=3047ce2b-8b2a-4df6-b43f-188b187e509c",
    label: {
      fr: "4.000 coins"
    },
    price: 40
  },
  {
    id: "coins_normal_3",
    wealthStatusId: 'normal',
    gainMoney: 10000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F1000_coins.svg?alt=media&token=b3fe86a5-6c60-4d1b-b4a7-cff2b396b848",
    label: {
      fr: "10.000 coins"
    },
    price: 80
  },
  // rich
  {
    id: "coins_rich_1",
    wealthStatusId: 'rich',
    gainMoney: 10000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F100_coins.svg?alt=media&token=c5cb35e7-c1ce-4861-911b-09b443f5af87",
    label: {
      fr: "10.000 coins"
    },
    price: 20
  },
  {
    id: "coins_rich_2",
    wealthStatusId: 'rich',
    gainMoney: 40000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F500_coins.svg?alt=media&token=3047ce2b-8b2a-4df6-b43f-188b187e509c",
    label: {
      fr: "40.000 coins"
    },
    price: 40
  },
  {
    id: "coins_rich_3",
    wealthStatusId: 'rich',
    gainMoney: 100000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F1000_coins.svg?alt=media&token=b3fe86a5-6c60-4d1b-b4a7-cff2b396b848",
    label: {
      fr: "100.000 coins"
    },
    price: 80
  },
  // ultra rich
  {
    id: "coins_ultrarich_1",
    wealthStatusId: 'ultrarich',
    gainMoney: 100000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F100_coins.svg?alt=media&token=c5cb35e7-c1ce-4861-911b-09b443f5af87",
    label: {
      fr: "100.000 coins"
    },
    price: 20
  },
  {
    id: "coins_ultrarich_2",
    wealthStatusId: 'ultrarich',
    gainMoney: 400000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F500_coins.svg?alt=media&token=3047ce2b-8b2a-4df6-b43f-188b187e509c",
    label: {
      fr: "400.000 coins"
    },
    price: 40
  },
  {
    id: "coins_ultrarich_3",
    wealthStatusId: 'ultrarich',
    gainMoney: 1000000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2F1000_coins.svg?alt=media&token=b3fe86a5-6c60-4d1b-b4a7-cff2b396b848",
    label: {
      fr: "1.000.000 coins"
    },
    price: 80
  }
]

export const shopItemsBankrupt = [
  // poor
  {
    id: "itembankrupt_poor_1",
    wealthStatusId: 'poor',
    gainMoney: 30,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Fblood_donation.svg?alt=media&token=fc68bd25-b00e-4344-a5a2-229ba6f3fecb",
    label: {
      fr: "Don du sang"
    },
    price: 10,
    priceType: 0 // enum GAUGE_TYPE.health
  },
  {
    id: "itembankrupt_poor_2",
    wealthStatusId: 'poor',
    gainMoney: 200,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Forgan_donation.svg?alt=media&token=e0f7b987-6d6b-47dc-bb4b-a306f2ef6eff",
    label: {
      fr: "Don d'organe"
    },
    price: 50,
    priceType: 0 // enum GAUGE_TYPE.health
  },
  // normal
  {
    id: "itembankrupt_normal_1",
    wealthStatusId: 'normal',
    gainMoney: 300,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Fblood_donation.svg?alt=media&token=fc68bd25-b00e-4344-a5a2-229ba6f3fecb",
    label: {
      fr: "Don du sang"
    },
    price: 10,
    priceType: 0 // enum GAUGE_TYPE.health
  },
  {
    id: "itembankrupt_normal_2",
    wealthStatusId: 'normal',
    gainMoney: 2000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Forgan_donation.svg?alt=media&token=e0f7b987-6d6b-47dc-bb4b-a306f2ef6eff",
    label: {
      fr: "Don d'organe"
    },
    price: 50,
    priceType: 0 // enum GAUGE_TYPE.health
  },
  // rich
  {
    id: "itembankrupt_rich_1",
    wealthStatusId: 'rich',
    gainMoney: 3000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Fblood_donation.svg?alt=media&token=fc68bd25-b00e-4344-a5a2-229ba6f3fecb",
    label: {
      fr: "Don du sang"
    },
    price: 10,
    priceType: 0 // enum GAUGE_TYPE.health
  },
  {
    id: "itembankrupt_rich_2",
    wealthStatusId: 'rich',
    gainMoney: 20000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Forgan_donation.svg?alt=media&token=e0f7b987-6d6b-47dc-bb4b-a306f2ef6eff",
    label: {
      fr: "Don d'organe"
    },
    price: 50,
    priceType: 0 // enum GAUGE_TYPE.health
  },
  // ultra rich
  {
    id: "itembankrupt_ultrarich_1",
    wealthStatusId: 'ultrarich',
    gainMoney: 30000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Fblood_donation.svg?alt=media&token=fc68bd25-b00e-4344-a5a2-229ba6f3fecb",
    label: {
      fr: "Don du sang"
    },
    price: 10,
    priceType: 0 // enum GAUGE_TYPE.health
  },
  {
    id: "itembankrupt_ultrarich_2",
    wealthStatusId: 'ultrarich',
    gainMoney: 200000,
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/arcs-app-b9cfb.appspot.com/o/production%2Fimages%2Fshop%2Forgan_donation.svg?alt=media&token=e0f7b987-6d6b-47dc-bb4b-a306f2ef6eff",
    label: {
      fr: "Don d'organe"
    },
    price: 50,
    priceType: 0 // enum GAUGE_TYPE.health
  }
]

export const imageKeyWords = [
  'cinéma',
  'zoo',
  'ours',
  'salon',
  'jeux vidéo',
  'bar',
  'comptoir',
  'rue',
  'billet',
  'argent',
  'piège',
  'magasin',
  'vêtements',
  'ruelle',
  'jour',
  'perso',
  'banane',
  'merde',
  'route',
  'basket',
  'sol',
  'sang',
  'rivière',
  'kayak',
  'castor',
  'exam',
  'logique',
  'tmp',
  'ordi',
  'voiture',
  'voisine',
  'twicky',
  'porte',
  'boxe',
  'parc',
  'forêt',
  'paintball',
  'lac',
  'punk/extra',
  'punk/intra',
  'leader de la troupe / extra',
  'leader de la troupe / intra',
  'dealer de la troupe / extra',
  'dealer de la troupe / intra',
  'chanteur de rue / extra',
  'chanteur de rue / intra',
  'nouvelle drogue / extra',
  'nouvelle drogue / intra',
  'pêche',
  'boite de nuit',
  'silhouette',
  'nuit',
  'coach',
  'mamie',
  'foodtruck',
  'fête foraine',
  'bonbon',
  'punching ball',
  'garçon',
  'poisson',
  'forain',
  'arme',
  'bagarre',
  'nuages',
  'coffre',
  'montagne',
  'piranha',
  'manège',
  'requin',
  'tribunal',
  'concert',
  'portefeuille',
  'salle de sport',
  'bio et géologie / intra',
  'bio et géologie / extra',
  'paléotonlogue / intra',
  'paléotonlogue / extra',
  'ingénieur aéronautique / intra',
  'formation pilote / intra',
  'être le meilleur pilote / intra',
  'être le meilleur pilote / extra',
  'sortir son premier album / extra',
  'sortir son premier album / intra',
  'baron de la drogue / intra',
  'baron de la drogue / extra'
]