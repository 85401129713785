// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import goals from './data/goals'
import users from './data/users'
import situations from './data/situations'
import activities from './data/activities'
import friends from './data/friends'
import badges from './data/badges'
import shop from './data/shop'
import images from './data/images'
import exports from './data/exports'

const rootReducer = {
  auth,
  navbar,
  layout,
  goals,
  activities,
  situations,
  friends,
  badges,
  shop,
  images,
  exports,
  users
}

export default rootReducer
