//** Redux Imports */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

/**
 * Used in src/db/realtime-db/...
 * Spread data accross the app, each time getXXXListener provides updates from Firebase
 */
export const getExports = createAsyncThunk('exports', async exports => {
  return exports
})

export const exportsSlice = createSlice({
  name: 'exports',
  initialState: {
    data: []
  },
  extraReducers: builder => {
    builder
      .addCase(getExports.fulfilled, (state, action) => {
        state.data = action.payload.sort((exp1, exp2) => (exp1._id > exp2._id ? -1 : 1))
      })
  }
})

export default exportsSlice.reducer