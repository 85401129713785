// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore"
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getDatabase } from "firebase/database"
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA354IgLryWuRAgNspPbR6ZsR5r4neu5DI",
  authDomain: "arcs-app-b9cfb.firebaseapp.com",
  projectId: "arcs-app-b9cfb",
  storageBucket: "arcs-app-b9cfb.appspot.com", 
  messagingSenderId: "651375432959",
  appId: "1:651375432959:web:d8f6f40bbcd3b826c2a8ca",
  measurementId: "G-RC6XQ34QV3",
  databaseURL: "https://arcs-app-b9cfb-default-rtdb.europe-west1.firebasedatabase.app"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
// TODO: comment the line below for prod app
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
// export const appCheck = initializeAppCheck(app, {
//   // Public key
//   provider: new ReCaptchaV3Provider('6Lcp98khAAAAAMdueiMu7tNVM2z8DsrtoPZXnSfR'),
//   // SDK automatically refreshes App Check
//   isTokenAutoRefreshEnabled: true
// })

// console.log(appCheck)

export const auth = getAuth(app)
export const storage = getStorage(app)
export const db = getDatabase(app)
export const firestoreDb = getFirestore(app)