// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

/**
 * Function used by getImagesListener in src/db/realtime-db/images
 * Spread images accross the app, each time getImagesListener provides updates on images
 */
export const getImages = createAsyncThunk('images', async images => {
  return images
})

export const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    data: {
      activities: []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getImages.fulfilled, (state, action) => {
        if (action.payload.activities) {
          state.data.activities = action.payload.activities
        }
      })
      // TODO: add getImages.rejected, getImages.pending, etc.
  }
})

export default imagesSlice.reducer