//** Redux Imports */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

/**
 * Used in src/db/realtime-db/...
 * Spread data accross the app, each time getXXXListener provides updates from Firebase
 */
export const getShop = createAsyncThunk('shop', async shop => {
  return shop
})

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    data: []
  },
  extraReducers: builder => {
    builder
      .addCase(getShop.fulfilled, (state, action) => {
        state.data = action.payload.sort((s1, s2) => { return s1.price < s2.price ? -1 : 1 })
      })
  }
})

export default shopSlice.reducer